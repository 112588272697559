import request from '@/utils/request'

// 记录对应的收货地址
export function getlist(params) {
  return request({
    url: 'redeem/Redeemlist',
    method: 'get',
    params: params
  })
}
// 兑换码查找单个兑换码
export function productinfo(params) {
  return request({
    url: 'redeem/product/codeinfo',
    method: 'get',
    params
  })
}
export function addressList(params) { // 获取地址列表
  return request({
    url: '/user/address/getUserAddressList',
    method: 'get',
    params
  })
}
export function set_default_address(params) { // 设置默认地址
  return request({
    url: '/user/address/updateAddress',
    method: 'get',
    params
  })
}
// id查找单个兑换码
export function productm(params) {
  return request({
    url: 'redeem/product/info',
    method: 'get',
    params
  })
}
// 领取兑换码
export function getredeem(params) {
  return request({
    url: 'redeem/get',
    method: 'get',
    params
  })
}
// 兑换
export function redeem(params) {
  return request({
    url: 'redeem/redeem',
    method: 'get',
    params
  })
}
// 京东 地址列表
export function getAddressList(params) {
  return request({
    url: '/user/Jdaddress/getUserJdAddressList',
    method: 'get',
    params
  })
}
// 京东设置默认地址
export function set_jdDefault_address(params) {
  return request({
    url: '/user/Jdaddress/setDefaultAddress',
    method: 'get',
    params
  })
}
// 京东添加新地址
export function addjdAddress(params) {
  return request({
    url: '/user/Jdaddress/editAddress',
    method: 'get',
    params
  })
}
// 京东获取省
export function getJdProvince(params) {
  return request({
    url: '/user/Jdaddress/getProvince',
    method: 'get',
    params
  })
}
// 京东获取市
export function getJdShi(params) {
  return request({
    url: '/user/Jdaddress/getCity',
    method: 'get',
    params
  })
}
// 京东获取区
export function getJdQu(params) {
  return request({
    url: '/user/Jdaddress/getCounty',
    method: 'get',
    params
  })
}
// 京东获取街道
export function getJdTown(params) {
  return request({
    url: '/user/Jdaddress/getTown',
    method: 'get',
    params
  })
}
